import React, { Fragment } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import ContactGlobal from "components/ContactGlobal"

import { Hero, Content } from "page_components/investments"

const Investment = ({ data }) => {
  const title = "Inwestycje w górach"

  const dataInvestment = {
    background:
      require("assets/images/investments/investment-hero-background-mountains.jpg")
        .default,
    icon: require("assets/images/investments/investment-hero-icon-mountains.svg")
      .default,
    description: (
      <Fragment>
        <li>Piękne krajobrazy</li>
        <li>Rozbudowana infrastruktura turystyczno-narciarska</li>
        <li>Bogata kultura góralska</li>
      </Fragment>
    ),
    investemnts: data?.allWpInvestment?.nodes?.length,
    region: "mountains",
  }

  return (
    <Layout
      seo={{
        title: title,
        description:
          "Inwestuj w górach Polski i osiągnij wysokie zyski dzięki rozwijającej się infrastrukturze turystycznej i malowniczej przyrodzie. Planuj z nami swoją inwestycję już dzisiaj!",
      }}
    >
      <Breadcrumbs
        title={title}
        parent={{
          title: "Portfel nieruchomości",
          path: "/portfel-nieruchomosci/",
        }}
      />
      <PageHeader title={title} />
      <Hero
        background={dataInvestment.background}
        icon={dataInvestment.icon}
        description={dataInvestment.description}
        investemntsLenght={dataInvestment.investemnts}
        allInvestemnts={data?.allWpInvestment?.nodes}
      />
      <Content data={data?.allWpInvestment?.nodes} />
      <ContactGlobal />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpInvestment(
      filter: {
        acfInvestment: { region: { eq: "mountains" }, status: { eq: "sell" } }
      }
      sort: { fields: acfInvestment___position, order: ASC }
    ) {
      nodes {
        id
        title
        uri
        slug
        acfInvestment {
          apartmensNumber
          areaNumberMin
          areaNumberMax
          apartmenPriceMin
          address
          status
          region
          logo {
            localFile {
              publicURL
            }
          }
          images {
            localFile {
              publicURL
            }
          }
          excerpt
          mapContent {
            latitude
            longitude
          }
        }
      }
    }
  }
`

export default Investment
